import React, { useContext } from 'react';

import StoreContext from './../../context/StoreContext';
import LineItem from './LineItem/LineItem';
import { getPrice } from '../../utils/currency';
import styled from '@emotion/styled';
import { Theme } from '@sashka/theme';
import { Button, Typography } from '@sashka/ui-lib';
import { Link } from 'gatsby';

export const Container = styled('section')<{ theme: Theme }>(({ theme }) => {
  return {
    // marginBottom: '1rem',
    width: '100%',
    background: theme.palette.background.site,
    marginBottom: '1.45rem',
    marginTop: '6rem',
    padding: `0 ${theme.gutters.base}px`,
  };
});

export const TakeMeShopping = styled(Button)<{ theme: Theme }>(({ theme }) => ({}));

export const LineItems = styled('ul')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gap: theme.gutters.base * 4,
  gridTemplateColumns: '1fr',
}));

const Cart = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  const handleCheckout = () => {
    window.open(checkout.webUrl);
  };

  const lineItems = checkout.lineItems.map(item => <LineItem key={item.id.toString()} item={item} />);

  const isCartEmpty: boolean = checkout.lineItems.length === 0;
  return (
    <div>
      {isCartEmpty ? (
        <>
          <Typography {...{ variant: 'h4', component: 'h2' }}>
            Oh, seems like we got nothing. Sad, sad indeed.
          </Typography>
          <Link {...{ to: '/' }}>
            <br />
            {/* <TakeMeShopping {...{ to: '/' }}>Take me shoping</TakeMeShopping> */}
            <TakeMeShopping {...{ variant: 'text' }}>Take me back shopping</TakeMeShopping>
          </Link>
        </>
      ) : (
        <>
          <LineItems>{lineItems}</LineItems>
          <Typography {...{ variant: 'h4', component: 'h2' }}>Subtotal</Typography>
          <Typography {...{ variant: 'body1', component: 'span' }}>
            {getPrice(checkout.subtotalPrice, checkout.currencyCode)}
          </Typography>

          <br />
          {/* <h2>Taxes</h2>
        <p>{getPrice(checkout.totalTax, checkout.currencyCode)}</p>
        <br /> */}
          {/* <h2>Total</h2>
        <p>{getPrice(checkout.totalPrice, checkout.currencyCode)}</p> */}
          <br />
          <Button {...{ onClick: handleCheckout, disabled: isCartEmpty }}>Proceed to checkout</Button>
        </>
      )}
    </div>
  );
};

export default Cart;
