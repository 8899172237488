import React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@sashka/theme';
import Cart from '../components/Cart/Cart';
import { Typography } from '@sashka/ui-lib';
// import { Container } from '../utils/styles';

export const Container = styled('section')<{ theme: Theme }>(({ theme }) => {
  return {
    maxWidth: theme.breakpoints.md,
    width: '100%',
    background: theme.palette.background.site,
    marginBottom: '1.45rem',
    marginTop: '6rem',
    padding: `0 ${theme.gutters.base}px`,
  };
});

const CartPage = () => (
  <Container>
    <Typography {...{ variant: 'h2', component: 'h1', margin: '0 0 3rem' }}>So, what have we got here?</Typography>
    <Cart />
  </Container>
);

export default CartPage;
