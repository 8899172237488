import React, { useContext } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

import StoreContext from '~/context/StoreContext';
import { Wrapper } from './styles';
import { Theme } from '@sashka/theme';
import { Typography, Button } from '@sashka/ui-lib';
import { getPrice } from '../../../utils/currency';
import Quantity from '../../Quantity/Quantity';

export const Container = styled('li')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gap: theme.gutters.base * 2,
  gridTemplateColumns: 'min-content 1fr min-content',
}));

export const InfoItem = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginTop: theme.gutters.base * 1,
  marginBottom: theme.gutters.base * 1,
}));

const LineItem = props => {
  const { item } = props;
  const {
    removeLineItem,
    updateLineItem,
    store: { client, checkout },
  } = useContext(StoreContext);

  const variantImage = item.variant.image ? (
    <img src={item.variant.image.src} alt={`${item.title} product shot`} height="60px" />
  ) : null;

  const selectedOptions = item.variant.selectedOptions
    ? item.variant.selectedOptions.map(option => `${option.name}: ${option.value}`)
    : null;

  const handleRemove = () => {
    removeLineItem(client, checkout.id, item.id);
  };

  return (
    <Container>
      <Link to={`/product/${item.variant.product.handle}/`}>{variantImage}</Link>
      <div>
        <Link to={`/product/${item.variant.product.handle}/`}>
          <Typography {...{ variant: 'h4' }}>
            {item.title}
            {`  `}
            {item.variant.title === !'Default Title' ? item.variant.title : ''}
          </Typography>
        </Link>
        {selectedOptions.map((option, index) => {
          return (
            <InfoItem {...{ key: index }}>
              <Typography {...{ variant: 'body2' }}>{option}</Typography>
            </InfoItem>
          );
        })}
        <InfoItem>
          <Typography {...{ variant: 'body2' }}>
            Unit Price - {getPrice(item.variant.price, checkout.currencyCode)}
          </Typography>
        </InfoItem>
        <InfoItem>
          <Typography {...{ variant: 'body2', component: 'span' }}>Quantity </Typography>
          <Quantity
            {...{
              count: item.quantity,
              onChange: quantity => {
                updateLineItem(client, checkout.id, item.id, quantity);
              },
            }}
          />
        </InfoItem>
      </div>

      <Button {...{ onClick: handleRemove, variant: 'text' }}>Remove</Button>
    </Container>
  );
};

export default LineItem;
