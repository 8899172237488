import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@sashka/theme';

interface QuantityProps {
  count: number;
  onChange: (quantity: number) => void;
}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'inline-grid',
  gap: theme.gutters.base * 0,
  gridTemplateColumns: `min-content minmax(auto, ${theme.gutters.base * 4}px) min-content`,
  border: `1px solid ${theme.palette.text.main}`,
  padding: `${theme.gutters.base * 0.5}px`,
}));

export const Sign = styled('div')<{ theme: Theme }>(({ theme }) => ({
  padding: `0 ${theme.gutters.base * 1}px`,
  cursor: 'pointer',
  userSelect: 'none',
}));

export const Input = styled('input')<{ theme: Theme }>(({ theme }) => ({
  padding: `0 ${theme.gutters.base * 1}px`,
  outline: 'none',
  border: 'none',
  textAlign: 'center',
}));

export const Quantity: React.FunctionComponent<QuantityProps> = ({ count = 1, onChange }) => {
  const [value, setValue] = React.useState<number>(count);

  const handleChange = newQuantity => {
    setValue(newQuantity);
    onChange(newQuantity);
  };

  return (
    <Container {...{}}>
      <Sign {...{ onClick: () => handleChange(value - 1) }}>-</Sign>
      <Input {...{ name: 'quantity', value, onChange: e => handleChange(Number(e.target.value)) }} />
      <Sign {...{ onClick: () => handleChange(value + 1) }}>+</Sign>
    </Container>
  );
};

export default Quantity;
